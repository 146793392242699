import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useDatepickerSupport } from 'common/hooks/useDatepickerSupport';
import { getIOSversion } from 'common/utils/getIOSversion';

// Ниже 15й версии datepicker не работает корректно, используем только нативный
const IOS_VERSION_WITH_ACCECTABLE_DATEPICKER = 15;

/**
 * Хук, проверяющий, можно ли полноценно отобразить нативный датапикер.
 * @returns булево значение, показывающее, можно ли отображать нативный датапикер.
 */
const useSupportedNativeCalendar = () => {
  const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);

  const [isSupportedIOS, setIsSupportedIOS] = useState(true);
  const isSupports = useDatepickerSupport();

  useEffect(() => {
    if (isMobile) {
      const [ver] = getIOSversion();
      const isIos = Boolean(ver);

      setIsSupportedIOS(!isIos ? true : ver > IOS_VERSION_WITH_ACCECTABLE_DATEPICKER);
    }
  }, [isMobile]);

  return isSupports && isSupportedIOS;
};

export default useSupportedNativeCalendar;

import { useEffect, useState } from 'react';

/**
 * Хук для проверки, поддерживается ли в данном браузере <input type='date' />
 * Способ определения поддержки описан в:
 * @see https://developer.mozilla.org/ru/docs/Web/HTML/Element/Input/date
 */
export const useDatepickerSupport = () => {
  const [isSupports, setIsSupports] = useState(true);

  useEffect(() => {
    const input = document.createElement('input');
    const value = 'a';
    input.setAttribute('type', 'date');
    input.setAttribute('value', value);
    setIsSupports(input.value !== value);
  }, []);

  return isSupports;
};

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { fetchCurrentLocation, GeoDataType } from 'common/utils/fetchCurrentLocation';

export const useFetchCurrentLocation = () => {
  const { locationApiUrl } = useSelector((state: IAppState) => state.runtime.config);

  const [currentLocation, setCurrentLocation] = useState<GeoDataType | null>(null);

  const fetchLocation = async () => {
    const location = await fetchCurrentLocation(locationApiUrl);
    setCurrentLocation(location);
  };

  useEffect(() => {
    fetchLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return currentLocation;
};

export type WidgetLocationsData = {
  id: string
  name: string
  city: string
  dateStart: string
  datePeriod: string[]
  timeOffset?: number
  links: {
    desktop: string
    mobile: string
  }
  pixels: {
    desktop: string
    mobile: string
  }
};

export const WIDGET_LOCATIONS: WidgetLocationsData[] = [
  {
    id:         '54119025',
    name:       'Краснодарский край',
    city:       'Сочи',
    dateStart:  '2024-06-14T23:59:00.000Z',
    datePeriod: ['2024-06-10T00:00:00.000Z', '2024-06-14T23:58:00.000Z'],
    links:      {
      desktop: 'https://trk.mail.ru/c/u582f3?mt_network=sberseller&mt_sub2=time&mt_sub1=desktop&mt_sub5=SberSeller_VKFest24_Media_WEB_CPM_SCH_all_Shokina_10/06/2024__Time&mt_sub3=SCH&erid=2RanynopPPN',
      mobile:  'https://trk.mail.ru/c/ao26p6?mt_network=sberseller&mt_sub2=time&mt_sub1=mobile&mt_sub5=SberSeller_VKFest24_Media_WEB_CPM_SCH_all_Shokina_10/06/2024__Time&mt_sub3=SCH&erid=2RanynopPPN',
    },
    pixels: {
      desktop: 'https://ad.adriver.ru/cgi-bin/rle.cgi?sid=1&bt=55&ad=787688&pid=4063070&bid=10685802&bn=10685802&exss=%rclid%&rnd=2053888034',
      mobile:  'https://ad.adriver.ru/cgi-bin/rle.cgi?sid=1&bt=55&ad=787688&pid=4063071&bid=10685804&bn=10685804&exss=%rclid%&rnd=2103306916',
    },
  },
  {
    id:         '54119086',
    name:       'Ростовская область',
    city:       'Сочи',
    dateStart:  '2024-06-14T23:59:00.000Z',
    datePeriod: ['2024-06-10T00:00:00.000Z', '2024-06-14T23:58:00.000Z'],
    links:      {
      desktop: 'https://trk.mail.ru/c/u582f3?mt_network=sberseller&mt_sub2=time&mt_sub1=desktop&mt_sub5=SberSeller_VKFest24_Media_WEB_CPM_SCH_all_Shokina_10/06/2024__Time&mt_sub3=SCH&erid=2RanynopPPN',
      mobile:  'https://trk.mail.ru/c/ao26p6?mt_network=sberseller&mt_sub2=time&mt_sub1=mobile&mt_sub5=SberSeller_VKFest24_Media_WEB_CPM_SCH_all_Shokina_10/06/2024__Time&mt_sub3=SCH&erid=2RanynopPPN',
    },
    pixels: {
      desktop: 'https://ad.adriver.ru/cgi-bin/rle.cgi?sid=1&bt=55&ad=787688&pid=4063070&bid=10685802&bn=10685802&exss=%rclid%&rnd=2053888034',
      mobile:  'https://ad.adriver.ru/cgi-bin/rle.cgi?sid=1&bt=55&ad=787688&pid=4063071&bid=10685804&bn=10685804&exss=%rclid%&rnd=2103306916',
    },
  },
  {
    id:         '54119007',
    name:       'Волгоградская область',
    city:       'Сочи',
    dateStart:  '2024-06-14T23:59:00.000Z',
    datePeriod: ['2024-06-10T00:00:00.000Z', '2024-06-14T23:58:00.000Z'],
    links:      {
      desktop: 'https://trk.mail.ru/c/u582f3?mt_network=sberseller&mt_sub2=time&mt_sub1=desktop&mt_sub5=SberSeller_VKFest24_Media_WEB_CPM_SCH_all_Shokina_10/06/2024__Time&mt_sub3=SCH&erid=2RanynopPPN',
      mobile:  'https://trk.mail.ru/c/ao26p6?mt_network=sberseller&mt_sub2=time&mt_sub1=mobile&mt_sub5=SberSeller_VKFest24_Media_WEB_CPM_SCH_all_Shokina_10/06/2024__Time&mt_sub3=SCH&erid=2RanynopPPN',
    },
    pixels: {
      desktop: 'https://ad.adriver.ru/cgi-bin/rle.cgi?sid=1&bt=55&ad=787688&pid=4063070&bid=10685802&bn=10685802&exss=%rclid%&rnd=2053888034',
      mobile:  'https://ad.adriver.ru/cgi-bin/rle.cgi?sid=1&bt=55&ad=787688&pid=4063071&bid=10685804&bn=10685804&exss=%rclid%&rnd=2103306916',
    },
  },
  {
    id:         '54119092',
    name:       'Астраханская область',
    city:       'Сочи',
    dateStart:  '2024-06-14T23:59:00.000Z',
    datePeriod: ['2024-06-10T00:00:00.000Z', '2024-06-14T23:58:00.000Z'],
    links:      {
      desktop: 'https://trk.mail.ru/c/u582f3?mt_network=sberseller&mt_sub2=time&mt_sub1=desktop&mt_sub5=SberSeller_VKFest24_Media_WEB_CPM_SCH_all_Shokina_10/06/2024__Time&mt_sub3=SCH&erid=2RanynopPPN',
      mobile:  'https://trk.mail.ru/c/ao26p6?mt_network=sberseller&mt_sub2=time&mt_sub1=mobile&mt_sub5=SberSeller_VKFest24_Media_WEB_CPM_SCH_all_Shokina_10/06/2024__Time&mt_sub3=SCH&erid=2RanynopPPN',
    },
    pixels: {
      desktop: 'https://ad.adriver.ru/cgi-bin/rle.cgi?sid=1&bt=55&ad=787688&pid=4063070&bid=10685802&bn=10685802&exss=%rclid%&rnd=2053888034',
      mobile:  'https://ad.adriver.ru/cgi-bin/rle.cgi?sid=1&bt=55&ad=787688&pid=4063071&bid=10685804&bn=10685804&exss=%rclid%&rnd=2103306916',
    },
  },
];

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { Typography } from 'common/components/Typography';
import { Icon } from 'common/components/Icon';

import { wordDeclination } from 'common/utils/wordDeclination';
import { getTop100Markup } from 'common/utils/getTop100Markup';

import { useCountdownTimer } from './useCountdownTimer';
import { useVisibleWidget } from './useVisibleWidget';
import { WIDGET_LOCATIONS } from './data';
import { useFetchCurrentLocation } from './useFetchCurrentLocation';

import s from './styles.css';

export const VkFest: React.FC = React.memo(() => {
  const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);

  const [infoOpen, setInfoOpen] = useState(false);

  const currentLocation = useFetchCurrentLocation();

  const top100Prefix = 'promo_widget_timer';

  const currentLocationId = currentLocation?.id;
  const currentLocationParentId = currentLocation?.parent?.id;
  const visibleLocation = WIDGET_LOCATIONS
    .find(({ id }) => id === currentLocationId || id === currentLocationParentId);

  const isVisible = useVisibleWidget(visibleLocation);
  const timer = useCountdownTimer(isVisible, visibleLocation);

  if (!isVisible || !visibleLocation) {
    return null;
  }

  const { pixels, links } = visibleLocation;

  const pixelSrc = isMobile ? pixels.mobile : pixels.desktop;
  const linkHref = isMobile ? links.mobile : links.desktop;

  return (
    <div className={cn(
      s.root,
      isMobile && s.mobile,
      infoOpen && s.infoOpen,
    )}
    >
      <img
        className={s.pixelImg}
        src={pixelSrc}
        aria-hidden="true"
        alt=""
      />
      <button
        type="button"
        aria-label="Открыть информацию о рекламодателе"
        className={s.adInfoButton}
        onClick={() => setInfoOpen(prev => !prev)}
      >
        <Icon id="icon-dots-10" className={s.infoIcon} />
      </button>
      {infoOpen && (
        <Typography
          variant="xSmallMedium"
          className={s.adInfo}
        >
          Рекламодатель: ООО «В КОНТАКТЕ».
          <br />
          ОГРН 1079847035179, ЮР. АДРЕС САНК-ПЕТЕРБУРГ,
          <br />
          ХЕРСОНСКАЯ УЛ. Д.12-14, ЛИТ.А.ПОМ. 1-Н.;
          Продавец билетов: ООО &quot;Интикетс&quot; 1107023, г. Москва, ул. Электрозаводская,
          27, стр. 8, ком. 1А. офис «Интикетс», ОГРН 1157746767364.
        </Typography>
      )}
      <div className={s.content}>
        <Typography
          variant="smallExtraBold"
          component="p"
          className={s.title}
        >
          До начала в&nbsp;
          {visibleLocation.city}
          &nbsp;осталось
        </Typography>
        <div className={s.timer}>
          <div className={s.timeBlock}>
            <Typography
              variant="h1"
              className={s.timeNumber}
            >
              {timer.days}
            </Typography>
            <Typography
              variant="smallMedium"
              className={s.timeText}
            >
              {wordDeclination(parseInt(timer.days, 10), 'days')}
            </Typography>
          </div>
          <div className={s.timeBlock}>
            <Typography
              variant="h1"
              className={s.timeNumber}
            >
              {timer.hours}
            </Typography>
            <Typography
              variant="smallMedium"
              className={s.timeText}
            >
              {wordDeclination(parseInt(timer.hours, 10), 'hours')}
            </Typography>
            <span className={s.timeDivider}>:</span>
          </div>
          <div className={s.timeBlock}>
            <Typography
              variant="h1"
              className={s.timeNumber}
            >
              {timer.minutes}
            </Typography>
            <Typography
              variant="smallMedium"
              className={s.timeText}
            >
              мин.
            </Typography>
            <span className={s.timeDivider}>:</span>
          </div>
          <div className={s.timeBlock}>
            <Typography
              variant="h1"
              className={s.timeNumber}
            >
              {timer.seconds}
            </Typography>
            <Typography
              variant="smallMedium"
              className={s.timeText}
            >
              сек.
            </Typography>
          </div>
        </div>
        <Typography
          variant="defaultBold"
          className={s.link}
          element={(
            <a
              target="_blank"
              rel="noreferrer noopener"
              aria-label="Купить билеты"
              href={linkHref}
              {...getTop100Markup(isMobile, top100Prefix)}
            />
          )}
        >
          Купить билеты
        </Typography>
        <p className={s.adText}>
          <span>0+ Реклама.&nbsp;</span>
          <span>Рекламодатель: ООО «В КОНТАКТЕ»</span>
        </p>
      </div>
    </div>
  );
});

import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { format, isWithinInterval } from 'date-fns';
import { createSelector } from 'reselect';

import Input from '@rambler-components/input';
import { Dropdown, DropdownWrapper } from '@rambler-components/dropdown';
import '@rambler-components/dropdown/styles.css';
import Snackbar, { snackbarEvent } from '@rambler-components/snackbar';
import '@rambler-components/snackbar/styles.css';
import Button from '@rambler-components/button';
import Calendar from '@rambler-components/calendar';

import { useTop100Context } from 'common/contexts/top100Context';
import { getTop100Markup } from 'common/utils/getTop100Markup';

import {
  getWithArgsSing,
  getWithArgsBirthdate,
} from 'config/constants/news-letter';

import {
  MIN_YEAR,
} from 'config/constants/calendar';

import useNewsLetterSubscribe from '../useNewsLetterSubscribe';
import useSupportedNativeCalendar from '../useSupportedNativeCalendar';

import s from './index.css';

interface ISubscribeForm {
  isVisible?: boolean
  subscribeKeys: string[]
  className?: string
  isShortMobile?: boolean
  onSuccess?: () => void
  onFailed?: () => void
  direction?: 'row' | 'column'
  inputType?: 'border' | 'fill'
}

const selectRuntimeData = createSelector(
  [
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.runtime.userEmail,
    (state: IAppState) => state.account.birthday,
    (state: IAppState) => state.runtime.config.apiSubscription,
    (state: IAppState) => state.runtime.config.newsLetterCrmKeys,
  ],
  (
    isMobile,
    userEmail,
    birthday,
    apiSubscription,
    newsLetterCrmKeys,
  ) => ({
    isMobile,
    userEmail,
    birthday,
    apiSubscription,
    newsLetterCrmKeys,
  }),
);

function SubscribeForm({
  subscribeKeys,
  isVisible,
  direction = 'column',
  inputType,
  isShortMobile,
  onSuccess,
  onFailed,
  className,
}: ISubscribeForm) {
  const inputDateRef = useRef<HTMLInputElement>();
  const inputMailRef = useRef<HTMLInputElement>();

  const {
    isMobile,
    userEmail,
    birthday,
    apiSubscription,
    newsLetterCrmKeys,
  } = useSelector(selectRuntimeData);

  // значения инпутов
  const [dateValue, setDateValue] = useState(birthday || '');
  const [emailValue, setEmailValue] = useState(userEmail || '');
  // открытие календаря
  const [isOpenCalendar, setOpenCalendar] = useState(false);
  // ошибки инпутов
  const [emailError, setEmailError] = useState(false);
  const [dateError, setDateError] = useState(false);

  const { onFetchSubscribe, isLoading } = useNewsLetterSubscribe();
  const isSupportedNativeCalendar = useSupportedNativeCalendar();

  const minDate = `${MIN_YEAR}-01-01`;
  const maxDate = format(new Date(), 'yyyy-MM-dd');

  const {
    top100Prefix,
  } = useTop100Context();

  // если для подписки не нужна дата рождения - инпут не показываем
  const isVisibleInputDate = subscribeKeys.length === 1
    ? (getWithArgsSing(newsLetterCrmKeys).includes(subscribeKeys[0])
      || getWithArgsBirthdate(newsLetterCrmKeys).includes(subscribeKeys[0])) : true;

  useEffect(() => {
    setDateValue(birthday || '');
    setEmailValue(userEmail || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail, birthday]);

  useEffect(() => {
    if (!isVisible) {
      if (inputMailRef?.current) {
        inputMailRef.current.blur();
      }

      if (inputDateRef?.current) {
        inputDateRef.current?.blur();
      }
      setOpenCalendar(false);
    }

    if (isMobile && isVisible) {
      // setTimeout нужен для ios
      // чтобы сначала открывался попап с формой
      //  а потом уже вызывалась клавиатура
      setTimeout(() => {
        if (inputMailRef?.current) {
          inputMailRef.current.focus();
        }
      }, 300);
    }
  }, [isVisible, isMobile]);

  const onSubscribe = async () => {
    // проверка на заполненные поля
    if (!emailValue || (!dateValue && isVisibleInputDate)) {
      if (!emailValue) setEmailError(true);
      if (!dateValue) setDateError(true);

      return;
    }

    // простая проверка почты
    if (!/[a-z0-9-_]+@[a-z-_]+\.[a-z]{2,15}/.test(emailValue)) {
      setEmailError(true);
      return;
    }

    const result = await onFetchSubscribe({
      email:    emailValue,
      birthday: dateValue,
      apiSubscription,
      subscribeKeys,
    });

    if (result.ok) {
      onSuccess?.();
    } else {
      snackbarEvent({
        message:           'Что-то не выходит. Попробуйте ещё раз',
        type:              'error',
        withCloseButton:   true,
        align:             'bottom center',
        autoCloseDuration: 5000,
      });
      onFailed?.();
    }
  };

  const onChangeCalendar = (val: Date) => {
    setOpenCalendar(false);
    setDateValue(format(new Date(val), 'yyyy-MM-dd'));
    setDateError(false);
  };

  const onFocusInputDate = () => {
    if (!isMobile || (isMobile && !isSupportedNativeCalendar)) {
      setOpenCalendar(true);
      setDateError(false);
    }
  };

  const onChangeInputDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isMobile && isSupportedNativeCalendar) {
      if (isWithinInterval(new Date(e.target.value), {
        start: new Date(minDate),
        end:   new Date(maxDate),
      })) {
        setDateValue(e.target.value);
        setDateError(false);
      } else {
        setDateError(true);
        setDateValue('');
      }
    }
  };

  const onChangeInputMail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
    setEmailError(false);
  };

  const onKeyPressMail = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isMobile) {
      if (e.key === 'Enter') {
        if (isSupportedNativeCalendar) {
          inputDateRef?.current?.showPicker();
        } else {
          setOpenCalendar(true);
        }

        if (inputDateRef?.current) {
          inputDateRef.current?.focus();
        }
      }
    }
  };

  return (
    <div className={cn(
      s.root,
      s[direction],
      isVisibleInputDate && !isShortMobile && s.visibleInputDate,
      className,
    )}
    >
      <Input
        forwardRef={inputMailRef as React.MutableRefObject<HTMLInputElement>}
        className={s.inputMail}
        type={inputType}
        status={emailError ? 'error' : undefined}
        placeholder="Электронная почта"
        value={emailValue}
        onChange={onChangeInputMail}
        autoComplete="email"
        onKeyDown={onKeyPressMail}
        {...getTop100Markup(isMobile, top100Prefix, 'subscribe_form::input_email')}
      />
      {isMobile && isSupportedNativeCalendar && (
        <div className={s.inputDateWrapper}>
          <Input
            forwardRef={inputDateRef as React.MutableRefObject<HTMLInputElement>}
            inputType="date"
            className={s.inputDate}
            type={inputType}
            status={dateError ? 'error' : undefined}
            placeholder="Дата рождения"
            value={dateValue}
            isNativeDatetimeMobile
            onChange={onChangeInputDate}
            min={minDate}
            max={maxDate}
            {...getTop100Markup(isMobile, top100Prefix, 'subscribe_form::input_birthdate')}
          />
        </div>
      )}
      {(!isMobile || (isMobile && !isSupportedNativeCalendar)) && (
        <DropdownWrapper className={s.inputDateWrapper}>
          <Input
            forwardRef={inputDateRef as React.MutableRefObject<HTMLInputElement>}
            inputType="date"
            className={s.inputDate}
            type={inputType}
            status={dateError ? 'error' : undefined}
            placeholder="Дата рождения"
            value={dateValue}
            isNativeDatetimeMobile={false}
            onFocus={onFocusInputDate}
            min={minDate}
            max={maxDate}
            {...getTop100Markup(isMobile, top100Prefix, 'subscribe_form::input_birthdate')}
          />
          <Dropdown
            autoPosition
            maxWidth={300}
            minWidth={300}
            isOpen={isOpenCalendar}
            onClose={() => setOpenCalendar(false)}
          >
            <Calendar
              minDate={new Date(minDate)}
              maxDate={new Date(maxDate)}
              isSelectable
              showYearSwitch
              onChange={onChangeCalendar}
            />
          </Dropdown>

        </DropdownWrapper>
      )}
      {!isShortMobile && (
        <Button
          isLoading={isLoading}
          onClick={onSubscribe}
          className={s.subscribeButton}
          {...getTop100Markup(isMobile, top100Prefix, 'subscribe_form::subscribe_button')}
        >
          Подписаться
        </Button>
      )}
      <Snackbar />
    </div>
  );
}

SubscribeForm.defaultProps = {
  className:     '',
  direction:     'column',
  inputType:     'fill',
  isVisible:     true,
  isShortMobile: false,
  onFailed:      () => {},
  onSuccess:     () => {},
};

export default SubscribeForm;

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  isWithinInterval,
  add,
} from 'date-fns';

import { WidgetLocationsData } from './data';

export const useVisibleWidget = (visibleLocation?: WidgetLocationsData) => {
  const serverInitialTime = useSelector((state: IAppState) => state.runtime.serverInitialTime);

  // для тестирования
  const { search } = useLocation();
  const testTime = new URLSearchParams(search).get('testTime');

  const isVisible = useMemo(() => {
    if (!visibleLocation) return false;
    try {
      const { timeOffset, datePeriod } = visibleLocation;

      // для тестирования
      const initTime = testTime || serverInitialTime;

      const currentDate = timeOffset
        ? add(new Date(initTime), { hours: timeOffset })
        : new Date(initTime);
      const startPeriodDate = new Date(datePeriod[0]);
      const endPeriodDate = new Date(datePeriod[1]);

      const inPeripod = isWithinInterval(new Date(currentDate), {
        start: new Date(startPeriodDate),
        end:   new Date(endPeriodDate),
      });

      return inPeripod;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Ошибка формата в isWithinInterval');
      return false;
    }
  }, [visibleLocation, serverInitialTime, testTime]);

  return isVisible;
};

import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import cn from 'classnames';

import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useHistory } from 'react-router';

import Button from '@rambler-components/button';
import { snackbarEvent } from '@rambler-components/snackbar';

import InputDate from 'common/components/InputDate';
import InputTime from 'common/components/InputTime';
import Select from '@rambler-components/select';
import Search from '@rambler-components/search';

import { safeGet } from 'utils/safeGet';

import { fetchHumanDesignGeoData } from 'common/redux/humanDesign';
import { fetchHumanDesignCitiesData, HDError } from 'utils/fetchSuggestedData';
import { checkTimeValue, getDisplayName } from 'common/utils/timeValue';

import { useTop100Context } from 'common/contexts/top100Context';
import { getTop100 } from 'common/components/Card/Form';
import { useDateByDeviceContext } from './contexts/dateByDeviceContext';

import s from './styles.css';

interface IFormProps {
  isMobile: boolean
  isExperimental: boolean
  maxYear: number
}

const selectGeoData = createSelector(
  [
    (state: IAppState) => state.humanDesign.places.countries,
    (state: IAppState) => state.humanDesign.isGeoError,
  ],
  (
    countries,
    isGeoError,
  ) => ({
    countries,
    isGeoError,
  }),
);

const selectAccount = createSelector(
  [
    (state: IAppState) => state.account,
  ],
  account => ({
    account,
  }),
);

const selectRuntime = createSelector(
  [
    (state: IAppState) => state.runtime,
  ],
  runtime => ({
    runtime,
  }),
);

function Form({
  isMobile,
  isExperimental,
  maxYear,
}: IFormProps) {
  const {
    countries,
    isGeoError,
  } = useSelector(selectGeoData);

  const {
    account,
  } = useSelector(selectAccount);

  const {
    runtime,
  } = useSelector(selectRuntime);

  const {
    top100Prefix,
  } = useTop100Context();
  const {
    isButtonClicked,
    setIsButtonClicked,
  } = useDateByDeviceContext();

  const dispatch = useDispatch();
  const history = useHistory();

  const [dateValue, setDateValue] = useState(account.birthday || '');
  const [timeValue, setTimeValue] = useState(account.birthtime || '');
  const [countryValue, setCountryValue] = useState('RU');
  const [cityValue, setCityValue] = useState<any>(null);
  const showGeoError = useRef(isGeoError);

  useEffect(() => {
    dispatch(fetchHumanDesignGeoData('places', 'countries'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsButtonClicked(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValue, timeValue, countryValue, cityValue]);

  useEffect(() => {
    setCityValue(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryValue]);

  useEffect(() => {
    showGeoError.current = isGeoError;
  }, [isGeoError]);

  const getSuggestedCountriesBlock = useMemo(() => {
    const suggestItems = safeGet(() => countries, []);

    if (!suggestItems || !suggestItems.length) return [];

    return suggestItems
      .sort((a: HumanDesignSuggestCountryType, b: HumanDesignSuggestCountryType) => {
        if (a.country_name < b.country_name) {
          return -1;
        }
        if (a.country_name > b.country_name) {
          return 1;
        }
        return 0;
      })
      .map((suggestItem: HumanDesignSuggestCountryType) => ({
        label: suggestItem.country_name,
        value: suggestItem.country_iso,
      }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  const submitButton = () => {
    setIsButtonClicked(true);

    if (showGeoError.current) {
      snackbarEvent({
        message:         'Что-то не выходит. Попробуйте ещё раз',
        type:            'error',
        align:           'bottom center',
        withCloseButton: true,
      });

      return;
    }

    if (cityValue && dateValue && timeValue && countryValue && checkTimeValue(timeValue)) {
      const urlParams = `?date=${dateValue}T${getDisplayName(timeValue)}&timezone=${cityValue.timezone}&place=${cityValue.ind}`;
      history.push(`/dizain-cheloveka/karta/${urlParams}`);
    }
  };

  const formAutocomplete = (
    placeholder: string,
    top100Tail: string,
    status?: 'default' | 'warning' | 'error',
    value?: any,
  ) => (
    <Search
      className={cn(
        s.input,
        s.inputHumanDesign,
        s.inputHumanDesignAutocomplete,
        isMobile && s.inputMobile,
        isExperimental && s.inputExperimental,
        s[`input-${status}`],
      )}
      placeholder={placeholder}
      showSearchButton={false}
      type="border"
      display="normal"
      action=""
      value={value}
      onSuggestAction={
        (query: string) => fetchHumanDesignCitiesData(
          { runtime },
          query,
          countryValue,
        )
          .then(data => {
            showGeoError.current = false;

            return data;
          })
          .catch((error: HDError) => {
            if (error.type === 'unavailable') {
              showGeoError.current = true;
            }

            return {
              query,
              suggests: [],
            };
          })
      }
      onSubmit={(e, query, submitDetails) => {
        e.preventDefault();
        if (submitDetails) {
          setCityValue(submitDetails?.suggestItem);
        }
      }}
      onClear={() => {
        setCityValue(null);
      }}
      {...getTop100(isMobile, top100Prefix, 'human_design_form', top100Tail || placeholder)}
    />
  );

  const formSelect = (
    suggest: any,
    placeholder: string,
    top100Tail: string,
    status?: 'success' | 'warning' | 'error',
    value?: any,
    setValue?: any,
  ) => (
    <Select
      className={cn(
        s.input,
        s.inputHumanDesign,
        s.inputHumanDesignAutocomplete,
        isMobile && s.inputMobile,
        isExperimental && s.inputExperimental,
      )}
      options={suggest}
      placeholder={placeholder}
      type="border"
      status={status}
      withSearch
      value={value}
      zIndex={200}
      onChange={setValue}
      {...getTop100(isMobile, top100Prefix, 'human_design_form', top100Tail || placeholder)}
    />
  );

  const formInput = (
    placeholder: string,
    top100Tail: string,
    type?: 'date' | 'time',
    status?: 'error' | 'warning' | 'success',
    value?: string,
    setValue?: any,
    topBound?: string,
  ) => (type === 'date'
    ? (
      <InputDate
        className={cn(
          s.inputHumanDesign,
          isMobile && s.inputMobile,
          isExperimental && s.inputExperimental,
        )}
        status={status}
        placeholder={placeholder}
        isMobile={isMobile}
        inputType={type}
        type="border"
        value={value}
        onChange={setValue}
        max={topBound}
        {...getTop100(isMobile, top100Prefix, 'human_design_form', top100Tail || placeholder)}
      />
    )
    : (
      <InputTime
        className={cn(
          s.inputHumanDesign,
          isMobile && s.inputMobile,
          isExperimental && s.inputExperimental,
        )}
        status={status}
        placeholder={placeholder}
        isMobile={isMobile}
        inputType={type}
        type="border"
        value={value}
        onChange={setValue}
        {...getTop100(isMobile, top100Prefix, 'human_design_form', top100Tail || placeholder)}
      />
    ));

  const formButton = (onClick?: any) => (
    <Button
      className={isMobile && s.buttonMobile}
      onClick={onClick || null}
      {...getTop100(isMobile, top100Prefix, 'human_design_form', 'calculation_button')}
    >
      Рассчитать
    </Button>
  );

  const formByType = [
    formInput(
      'Дата рождения',
      'birthday',
      'date',
      isButtonClicked && !dateValue ? 'error' : undefined,
      dateValue,
      setDateValue,
      `${maxYear}-12-31`,
    ),
    formInput(
      'Время рождения',
      'time_of_birth',
      'time',
      isButtonClicked && !timeValue ? 'error' : undefined,
      timeValue,
      setTimeValue,
    ),
    formSelect(
      getSuggestedCountriesBlock,
      'Страна рождения',
      'country',
      isButtonClicked && !countryValue ? 'error' : undefined,
      countryValue,
      setCountryValue,
    ),
    formAutocomplete(
      'Город рождения',
      'city',
      isButtonClicked && (!cityValue || !cityValue.descr) ? 'error' : undefined,
      cityValue ? cityValue.descr : '',
    ),
    formButton(
      submitButton,
    ),
  ];

  return (
    <>
      {formByType.map((item: JSX.Element, index: number) => {
        const key = `Form-${index}`;

        return (
          <Fragment key={key}>
            {item}
          </Fragment>
        );
      })}
    </>
  );
}

export { Form };

import React, {
  useCallback,
  useState,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import Cookies from 'js-cookie';

import { AdditionalLinkType, SocialLinkType } from 'packages/topline-mobile/typings/index';
import Topline, { UserProfileType } from '@rambler-components/topline-mobile';
import Popup from '@rambler-components/popup';
import Geo from '@rambler-components/geo';

import '@rambler-components/topline-mobile/styles.css';

import { YANDEX_METRICS } from 'config/constants/counters';
import YandexEvent from 'utils/counters/YandexEvent';
import { safeGet } from 'utils/safeGet';
import top100Wrapper from 'utils/counters/top100Wrapper';
import { getTopics } from 'common/utils/navigation';
import { SET_RUNTIME_VARIABLE } from 'common/redux/runtime';
import { createMenuLinks, socialLinks } from 'config/constants/footer';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.currentBurgerSection,
    (state: IAppState) => state.runtime.currentParams.zodiacSign,
    (state: IAppState) => state.runtime.currentParams.chineseSign,
    (state: IAppState) => state.runtime.currentParams.chineseCompatibilitySign,
  ],
  (
    currentBurgerSection,
    zodiacSign,
    chineseSign,
    chineseCompatibilitySign,
  ) => ({
    currentBurgerSection,
    zodiacSign,
    chineseSign,
    chineseCompatibilitySign,
  }),
);

type Props = {
  isInDesktop?: boolean
};

function ToplineMobile({ isInDesktop }: Props) {
  const dispatch = useDispatch();
  const [geoOpened, setGeoOpened] = useState(false);
  const [reloadGeoKey, setReloadGeoKey] = useState(1);
  const [userProfile, setUserProfile] = useState<UserProfileType | null>(null);

  const {
    currentBurgerSection,
    zodiacSign,
    chineseSign,
    chineseCompatibilitySign,
  } = useSelector(selectData);

  const finalChineseSign = chineseSign || chineseCompatibilitySign;

  const topics = useMemo(
    () => getTopics(currentBurgerSection, true, zodiacSign, finalChineseSign),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [zodiacSign, finalChineseSign, currentBurgerSection],
  );

  const onUserId = useCallback((userId?: string) => {
    dispatch({
      type:  SET_RUNTIME_VARIABLE,
      name:  'userId',
      value: userId,
    });
    top100Wrapper.sendUserId(userId);
  }, [dispatch]);

  const onUserInfo = useCallback((userInfo: UserProfileType) => {
    if (!userProfile) {
      dispatch({
        type:  SET_RUNTIME_VARIABLE,
        name:  'userEmail',
        value: safeGet(() => userInfo!.info.email, ''),
      });

      setUserProfile(userInfo);
    }
  }, [userProfile]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeLocation = useCallback(() => {
    setReloadGeoKey(prevGeoKey => prevGeoKey + 1);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Topline
        projectCode="horoscopes"
        zIndex={50}
        reloadGeoKey={reloadGeoKey}
        hiddenSearch={!!isInDesktop}
        projectLinks={topics}
        additionalLinks={createMenuLinks(true) as AdditionalLinkType[]}
        socialLinks={socialLinks as SocialLinkType[]}
        showLegalRules
        onUserId={onUserId}
        onUserInfo={onUserInfo}
        onUserState={e => {
          (new YandexEvent(YANDEX_METRICS.COMMON)).send({
            type:   'reachGoal',
            data:   `user_${e}`,
            params: {
              auth_type: e === 'login' ? 'ID_authorized' : 'ID_unauthorized',
            },
          }, () => {
            Cookies.remove('haccount');
            window.location.reload();
          });
        }}
        onOpenGeo={() => setGeoOpened(true)}
      />
      <Popup width={560} zIndex={5005} isOpen={geoOpened} onClose={() => setGeoOpened(false)}>
        <Geo onChange={onChangeLocation} onClose={() => setGeoOpened(false)} />
      </Popup>
    </>
  );
}

ToplineMobile.defaultProps = {
  isInDesktop: false,
};

export { ToplineMobile };

import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import {
  addSeconds,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  add,
} from 'date-fns';

const TIMER_INTERVAL = 1000;

const INIT_TIME_STATE = {
  days:    '00',
  hours:   '00',
  minutes: '00',
  seconds: '00',
};

export const useCountdownTimer = (isVisible: boolean, visibleLocation: any) => {
  const timerId = useRef<ReturnType<typeof setInterval>>();
  const [timer, setTimer] = useState(INIT_TIME_STATE);

  // для тестирования
  const { search } = useLocation();
  const testTime = new URLSearchParams(search).get('testTime');

  // для тестирования
  const testTimeRef = useRef(testTime ? new Date(testTime) : null);

  const updateTime = () => {
    if (!visibleLocation) return;

    const { dateStart, timeOffset } = visibleLocation;

    const startEvendDate = timeOffset
      ? add(new Date(dateStart), { hours: timeOffset })
      : new Date(dateStart);

    if (testTimeRef.current) {
      testTimeRef.current = addSeconds(new Date(testTimeRef.current), 1);
    }

    const currendDate = testTimeRef.current
      ? testTimeRef.current : new Date();

    const days = differenceInDays(startEvendDate, currendDate);
    const hours = differenceInHours(startEvendDate, add(currendDate, { days }));
    const minutes = differenceInMinutes(startEvendDate, add(currendDate, { days, hours }));
    const seconds = differenceInSeconds(
      startEvendDate,
      add(currendDate, { days, hours, minutes }),
    );

    const toStringValue = (number: number): string => {
      if (number.toString().length === 1) {
        return `0${number}`;
      }
      return number.toString();
    };

    const values = [days, hours, minutes, seconds];

    const isEndTime = values.reduce((acc, val) => acc + val, 0) < 1;

    if (isEndTime && timerId.current) {
      clearInterval(timerId.current);
      setTimer(INIT_TIME_STATE);
      return;
    }

    setTimer({
      days:    toStringValue(days),
      hours:   toStringValue(hours),
      minutes: toStringValue(minutes),
      seconds: toStringValue(seconds),
    });
  };

  useEffect(() => {
    if (isVisible && visibleLocation) {
      updateTime();

      timerId.current = setInterval(updateTime, TIMER_INTERVAL);
    }

    return () => {
      if (timerId.current) {
        clearInterval(timerId.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, visibleLocation]);

  return timer;
};
